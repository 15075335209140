<template>
  <div>
    <Header>
      <template v-slot:title> Brokeree </template>
    </Header>
    <div class="m-5">
      <!-- <st-client-app
        id="embed-id"
        st-product-host="https://social.main.pres.brokeree.dev"
        st-assets-path="/portal/assets"
        st-widget-lang="en"
        st-widget-theme="light"
        st-widget-token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJIZWxsbyIsIm5hbWUiOiJUaGlzIGlzIGFuIGV4YW1wbGUgb2YgYSB0b2tlbiIsInJvbGUiOiIiLCJ1c2VyX2lkIjoiIiwic2VydmVyX2lkIjoiIiwiYWQubGUiOiIiLCJ0b2tlbl91c2FnZSI6IiIsImp0aSI6IiIsImNmZF9sdmwiOiIiLCJzY29wZSI6WyJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiXSwibmJmIjoxLCJleHAiOjEsImlhdCI6MSwiaXNzIjoiaHR0cHM6Ly9zb2NpYWwubXlhd2Vzb21lYm9ya2VyYWdlLmNvbS8ifQ.CNUbpRNFtdiJG0wWP_-D8Lx1Gg2ININ8TPVC65tRVWQ"
      ></st-client-app> -->
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header";

export default {
  name: "Brokeree",
  components: {
    Header,
  },
  data() {
    return {
      url: "",
    };
  },
  computed: {
    lang() {
      let language = this.$store.getters.get_lang;
      if (language === "zh-Hant") {
        language = "zh-hant";
      }
      if (language === "zh-Hans") {
        language = "zh-hans";
      }
      return language;
    },
  },
  watch: {
    lang() {
      this.getUrl();
    },
  },
  created() {
    this.$store.dispatch("brokeree_token", "2227167");
  },
  methods: {
    getUrl() {
      this.$store.dispatch("analysis_url", this.lang).then((resp) => {
        this.url = resp.data.url;
      });
    },
  },
};
</script>